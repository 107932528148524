import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

// import media from 'styles/media'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  video,
  iframe {
    display: flex;
    z-index: 1;
  }
`

type Props = {
  localVideoSrc: string
  autoplay?: boolean
}

const VideoPlayer: React.FC<Props> = ({ localVideoSrc, autoplay = true }) => {
  return (
    <Wrapper>
      <VideoWrapper>
        <ReactPlayer
          url={localVideoSrc}
          width="100%"
          height="100%"
          playing={autoplay}
          controls={!autoplay}
          muted={autoplay}
          loop={autoplay}
        />
      </VideoWrapper>
    </Wrapper>
  )
}

export default VideoPlayer
