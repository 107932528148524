import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import VideoPlayer from 'components/video-player'

import { scrollToNextSibling } from 'utils/scrollToSection'

import media from 'styles/media'

import arrowDown from 'assets/icons/arrow-down.svg'

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 70px;

  ${media.lg.min} {
    display: flex;
    align-items: center;
    padding-top: 100px;
  }
`

const StyledDiscoverButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  width: 138px;
  height: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    width: 113px;
    height: 113px;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: 50%;
    width: 89px;
    height: 89px;
    z-index: -1;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  ${media.lg.min} {
    /* display this button above overlay on contact page */
    z-index: 1;

    &:hover {
      &:after {
        width: 138px;
        height: 138px;
      }
    }
  }

  ${media.lg.max} {
    display: none;
    width: 63px;
    height: 63px;
    bottom: 16px;
    background-color: rgba(255, 255, 255, 0.25);

    &:after {
      width: 43px;
      height: 43px;
      background-color: rgba(255, 255, 255, 0.35);
    }

    &:before {
      display: none;
    }
  }
`

const StyledIcon = styled.img`
  width: 9px;

  ${media.lg.min} {
    width: 20px;
  }
`

const StyledContainer = styled(Container)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;

  ${media.lg.min} {
    padding-top: 0;
    width: 50%;
  }
`

const StyledHeading = styled.h4`
  font-size: 30px;
  margin-bottom: 20px;
  color: #ffffff;

  ${media.lg.min} {
    font-size: 45px;
    margin-bottom: 35px;
  }
`

const StyledText = styled.h2`
  font-size: 18px;
  font-weight: normal;
  font-family: 'Open Sans';
  color: #ffffff;

  ${media.lg.min} {
    font-size: 24px;
  }
`

type HeaderWithVideoProps = {
  video: string
  title: string
  subtitle: string
}

const HeaderWithVideo: React.FC<HeaderWithVideoProps> = ({
  video,
  title,
  subtitle,
}) => {
  return (
    <StyledWrapper id="header">
      <VideoPlayer localVideoSrc={video} />

      <StyledContainer>
        <StyledInnerWrapper>
          <StyledHeading as="h4" dangerouslySetInnerHTML={{ __html: title }} />
          <StyledText dangerouslySetInnerHTML={{ __html: subtitle }} />
        </StyledInnerWrapper>
      </StyledContainer>

      <StyledDiscoverButton
        onClick={() => scrollToNextSibling('#header')}
        aria-label="zjedź niżej"
      >
        <StyledIcon src={arrowDown} alt="strzałka w dół" />
      </StyledDiscoverButton>
    </StyledWrapper>
  )
}

export default HeaderWithVideo
