import React, { lazy, Suspense } from 'react'
import LazyLoad from 'react-lazyload'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Header from 'components/header'
import HeaderWithVideo from 'components/header-with-video'

import useBreakpoint from 'hooks/useBreakpoint'

import { IndexPageQuery } from 'types/graphql'

const About = lazy(() => import('components/about'))
const BlogPosts = lazy(() => import('components/blog-posts'))
const Rooms = lazy(() => import('components/pages/homepage/rooms'))
const Newsletter = lazy(() => import('components/newsletter'))
const Inspirations = lazy(
  () => import('components/pages/homepage/inspirations')
)
const ActionBoxContact = lazy(() => import('components/action-box-contact'))

const IndexPage: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.mainPage
  const PAGE_SEO = data?.wpPage?.seo!

  const ROOMS = PAGE?.mainPageS4List?.map((room) => ({
    image: {
      src: room?.mainPageS4ListElementImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: room?.mainPageS4ListElementImg?.altText!,
    },
    name: room?.mainPageS4ListElementTitle!,
  }))

  const INSPIRATIONS_GALLERY =
    PAGE?.mainPageS6List?.map((item) => ({
      images:
        item?.mainPageS6ListElementPictures?.map((image) => ({
          src: image?.mainPageS6ListElementPicturesImg?.localFile
            ?.childImageSharp?.gatsbyImageData!,
          alt: image?.mainPageS6ListElementPicturesImg?.altText!,
        })) || [],
      heading: item?.mainPageS6ListElementBeforeTitle!,
      text: item?.mainPageS6ListElementTitle!,
    })) || []

  const BLOG_POSTS =
    data?.allWpPost?.nodes?.map(({ title, slug, date, featuredImage }) => ({
      title: title!,
      slug: slug!,
      date: new Date(date).toLocaleDateString(),
      image: {
        src: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: featuredImage?.node?.altText!,
      },
    })) || []

  const { md } = useBreakpoint()

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      {PAGE?.mainPageS1ImgFilm?.localFile?.publicURL ? (
        <HeaderWithVideo
          video={PAGE?.mainPageS1ImgFilm?.localFile?.publicURL!}
          title={PAGE?.mainPageS1Title!}
          subtitle={PAGE?.mainPageS1Paragraph!}
        />
      ) : (
        <Header
          image={{
            src: PAGE?.mainPageS1Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE?.mainPageS1Img?.altText!,
          }}
          title={PAGE?.mainPageS1Title!}
          subtitle={PAGE?.mainPageS1Paragraph!}
          page="strona główna"
        />
      )}

      <LazyLoad once offset={md ? 300 : 150}>
        <Suspense fallback={<></>}>
          <About
            image={{
              src: PAGE?.mainPageS2Img?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.mainPageS2Img?.altText!,
            }}
            title={PAGE?.mainPageS2Title!}
            text={PAGE?.mainPageS2Paragraph!}
          />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={md ? 300 : 150}>
        <Suspense fallback={<></>}>
          <BlogPosts
            layout="homepage"
            heading={PAGE?.mainPageS3Title!}
            buttonText={PAGE?.mainPageS3ButtonText!}
            posts={BLOG_POSTS!}
          />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={md ? 300 : 150}>
        <Suspense fallback={<></>}>
          <Rooms
            heading={PAGE?.mainPageS4Title!}
            rooms={ROOMS!}
            buttonText={PAGE?.mainPageS4ButtonText!}
          />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={md ? 300 : 150}>
        <Suspense fallback={<></>}>
          <Newsletter
            heading={PAGE?.mainPageS5Title!}
            text={PAGE?.mainPageS5Paragraph!}
            buttonText={PAGE?.mainPageS5ButtonText!}
          />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={md ? 300 : 150}>
        <Suspense fallback={<></>}>
          <Inspirations
            heading={PAGE?.mainPageS6Title!}
            gallery={INSPIRATIONS_GALLERY!}
          />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={md ? 300 : 150}>
        <Suspense fallback={<></>}>
          <ActionBoxContact
            image={{
              src: PAGE?.mainPageS7Img?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.mainPageS7Img?.altText!,
            }}
            heading={PAGE?.mainPageS7Paragraph!}
            buttonText={PAGE?.mainPageS7ButtonText!}
          />
        </Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    wpPage(slug: { regex: "/strona-glowna/" }) {
      seo {
        ...WpSEO
      }
      mainPage {
        mainPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        mainPageS1ImgFilm {
          altText
          localFile {
            publicURL
          }
        }
        mainPageS1Paragraph
        mainPageS1Title
        mainPageS2Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        mainPageS2Paragraph
        mainPageS2Title
        mainPageS3ButtonText
        mainPageS3Title
        mainPageS4ButtonText
        mainPageS4List {
          mainPageS4ListElementTitle
          mainPageS4ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
        mainPageS4Title
        mainPageS5ButtonText
        mainPageS5Paragraph
        mainPageS5Title
        mainPageS6List {
          mainPageS6ListElementBeforeTitle
          mainPageS6ListElementTitle
          mainPageS6ListElementPictures {
            mainPageS6ListElementPicturesImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
        }
        mainPageS6Title
        mainPageS7ButtonText
        mainPageS7Paragraph
        mainPageS7Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
      }
    }
    allWpPost(limit: 3) {
      nodes {
        title
        slug
        date
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
  }
`
